.e3-bg {
  min-height: 540px;
  /* flex-shrink: 0; */
  background-image: url("../../../../assets/images/e3Bg.png");
  background-size: cover;
  background-repeat: no-repeat;
}

.custom-text {
  color: var(--White-white-50, #feffff);
  font-family: Poppins;
  font-size: 48px;
  font-style: normal;
  font-weight: 500;
  line-height: 55px;
}

@media (max-width: 425px) {
  .custom-text {
    font-size: 24px;
    line-height: 42px;
  }
  .e3-bg {
    min-height: 330px;
    background-size: cover;
  }
}

@media (min-width: 426px) and (max-width: 1024px) {
  .custom-text {
    font-size: 48px;
    line-height: 55px;
  }
  .e3-bg {
    min-height: 504px;
    background-size: cover;
  }
}
