.navbar-container {
  position: fixed;
  display: flex;
  /* width: 100%; */
  /* padding: 3.5rem 21.5rem 1rem 21.5rem; */
  /* margin-top: 0.5rem; */
  padding: 1rem 344px;
  justify-content: space-between;
  align-items: center;
  background: var(--Black-black-700, #0d0d0d);
  z-index: 1;
  /* left: 14rem;
  right: 14rem; */
  /* border-radius: 40px; */
  width: 100%;
  /* Add this for the blur effect */
  backdrop-filter: blur(20px);
  -webkit-backdrop-filter: blur(20px);
}

.text-decoration {
  color: var(--White-white-50, #feffff);
  font-family: Poppins;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  cursor: pointer;
}
