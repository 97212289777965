.image-container {
  min-height: 910px;
  /* flex-shrink: 0; */
  background-image: url("../../../assets/images/aboutBg.png");
  background-position: center;
  background-attachment: fixed;
}

.header-txt {
  color: var(--White-white-50, #feffff);
  font-family: Poppins;
  font-size: 48px;
  font-style: normal;
  font-weight: 500;
  line-height: 50px; /* 125% */
}

.feedback-section {
  min-height: 868px;
  background-image: url("../../../assets/images/aboutBgTwo.png");
  background-size: cover;
  /* background-attachment: fixed; */
  background-position: center;
  background-repeat: no-repeat;
}

/* Size for screens ≤ 425px */
@media (max-width: 425px) {
  .header-txt {
    font-size: 24px;
    font-weight: 500;
    line-height: 42px;
  }
  .image-container {
    min-height: 330px;
    background-size: cover;
  }
}

@media (min-width: 426px) and (max-width: 768px) {
  .image-container {
    min-height: 504px;
    background-size: cover;
  }
}
