li {
  font-size: 16px;
  font-weight: 500;
}

.primary-text {
  margin-top: 12px;
  font-size: 20px;
  font-weight: 600;
}

.secondary-text {
  margin-top: 12px;
  font-size: 16px;
  font-weight: 600;
}

.paragraph-text {
  margin-top: 8px;
  font-size: 16px;
  font-weight: 500;
}
