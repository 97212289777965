.cloud-bg {
  /* max-width: 1920px; */
  min-height: 910px;
  /* flex-shrink: 0; */
  background-image: url("../../../../assets/images/cloudBg.png");
  background-size: cover;
  background-repeat: no-repeat;
}

.cloud-bg-two {
  min-height: 569px;
  /* flex-shrink: 0; */
  background-image: url("../../../../assets/images/cloudBgTwo.png");
  background-size: cover;
  background-repeat: no-repeat;
}

.bg-text {
  color: var(--White-white-50, #feffff);
  font-family: Poppins;
  font-size: 48px;
  font-style: normal;
  font-weight: 500;
  line-height: 80px;
}

/* Size for screens ≤ 425px */
@media (max-width: 425px) {
  .bg-text {
    font-size: 24px;
    line-height: 42px;
  }
  .cloud-bg {
    min-height: 330px;
    background-size: cover;
  }
}

@media (min-width: 426px) and (max-width: 1024px) {
  .bg-text {
    font-size: 48px;
    line-height: 55px;
  }
  .cloud-bg {
    min-height: 504px;
    background-size: cover;
  }
}

.ant-collapse-item {
  margin-bottom: 16px;
}

.ant-collapse-expand-icon {
  padding-top: 5px;
}

.ant-collapse-content-box {
  padding-left: 0 !important;
}

.ant-collapse-content-box p {
  margin: 1rem 0;
  font-size: 16px;
  font-weight: 400;
}

.ant-collapse-header {
  font-size: 16px;
  font-weight: 500;
  border: 1px solid #646464;
  padding: 16px 0px 16px 16px;
  border-radius: 4px;
}
