.footer-container {
  display: flex;
  /* width: 1920px; */
  /* padding: 64px 200px 32px 200px; */
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: var(--Black-black-700, #0d0d0d);
}

.ant-input-affix-wrapper {
  background-color: rgba(137, 137, 137, 0.8) !important;
}

.ant-input::placeholder {
  color: rgba(255, 255, 255, 0.7);
  padding-left: 8px;
}

.email-text {
  width: 300px;
  font-size: 16px;
  padding: 8px 16px;
  color: #ffffff;
}
