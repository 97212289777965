@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  .custom-padding {
    @apply px-2 md:px-6 xl:px-40 2xl:px-[344px];
  }
}

* {
  margin: 0;
  padding: 0;
}

/* Scrollbar styles */
/* width */
::-webkit-scrollbar {
  height: 6px;
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #b1b1b1;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.fade-in {
  opacity: 0;
  animation: fadeIn 0.5s ease-in forwards;
}
