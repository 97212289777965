.home-container {
  /* max-width: 1920px; */
  /* width: 100%; */
  min-height: 868px;
  flex-shrink: 0;
  background: linear-gradient(
      180deg,
      #0a0a0a 6.91%,
      rgba(0, 0, 0, 0) 64.92%,
      rgba(0, 0, 0, 0) 80.18%,
      #0a0a0a 97.72%
    ),
    url("../../../assets/images/homeBg.png") no-repeat center center;
  background-size: cover;
  background-repeat: no-repeat;
  background-color: lightgray;
}

.text-animation {
  opacity: 0;
  animation: slideFromTop 1s ease-in-out 0.5s forwards;
}

@keyframes slideFromTop {
  0% {
    opacity: 0;
    transform: translateY(-20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.header-text {
  color: var(--White-white-50, #feffff);
  font-family: Poppins;
  font-size: 40px;
  font-style: normal;
  font-weight: 500;
  line-height: 50px;
}

.body-text {
  color: var(--White-white-50, #feffff);
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 100;
  opacity: 0.9;
  line-height: normal;
}

@media (max-width: 425px) {
  .header-text {
    font-size: 24px; /* 24px font size for screens ≤ 425px */
    font-weight: 500;
    line-height: 42px;
  }
}

@media (min-width: 426px) and (max-width: 768px) {
  .home-container {
    min-height: 700px;
    background-size: cover;
  }
}
