.quantity-input {
    display: flex;
    align-items: center;
    border: 1px solid #ccc;
    border-radius: 5px;
    overflow: hidden;
    width: 120px;
    height: 28px;
}

.quantity-btn {
    border-right: 1px solid #ddd;
    width: 40px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    font-size: 20px;
}

/* .quantity-btn.minus{
border-radius: 6px 0px 0px 6px;
} */

.quantity-btn.plus{
 border-left: 1px solid #ddd;   
}

.quantity-field {
    text-align: center;
    border: none;
    width: 40px;
    height: 100%;
    font-size: 16px;
    outline: none;
}

.quantity-field::-webkit-outer-spin-button,
.quantity-field::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.quantity-field {
    -moz-appearance: textfield;
}

.ant-tabs-tab .ant-tabs-tab-btn{
display: flex !important;
align-items: center !important;
}

/*===============Specification tab==============*/

.specification-container {
    width: 100%;
    max-width: 600px;
    margin: 20px auto;
    padding: 10px;
    font-family: 'Poppins',sans-serif;
}

.specification-row {
    display: flex;
    justify-content: space-between;
    margin-bottom: 12px;
}

.label {
    width: 40%;
}

.value {
    width: 60%;
}

.label {
    font-size: 14px;
}

.value {
    font-size: 16px;
}

/* @media (max-width: 768px) {

    .specification-row {
        flex-direction: column;
    }

    .label,
    .value {
        width: 100%;
        text-align: left;
        margin-bottom: 8px;
    }
} */