:root {
  --Black-black-700: #0d0d0d;
  --White-white-50: #feffff;
}

@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;700&display=swap");

body {
  font-family: "Poppins", "Arial", sans-serif;
}
