.product-banner {
  background: url("../../../assets/images/product-banner.png");
  background-attachment: fixed;
  background-repeat: no-repeat;
  height: 450px;
  /* background-position: center; */
}
.search-product .ant-input-affix-wrapper {
  background-color: white !important;
}

.search-product .ant-input::placeholder {
  color: #c9c9cc;
  font-size: 13px;
  font-weight: medium;
  /* Set your desired placeholder color */
}
.categories .ant-checkbox-group .ant-checkbox-wrapper {
  margin-bottom: 24px;
}
.categories .ant-checkbox-group .ant-checkbox-wrapper span {
  color: #4a4b53;
  font-size: 16px;
  font-weight: 400;
  font-family: "Poppins", sans-serif;
}
/* .categories .ant-checkbox-group .ant-checkbox-wrapper:first-child{
    margin-bottom: 0px;
} */

.ant-pagination li {
  width: 35px;
  height: 35px;
  background-color: #eeeeef !important;
}
.ant-pagination-item-active {
  border: black !important;
}

.ant-pagination-item-active a {
  background-color: black !important;
  border-radius: 5px !important;
  color: white !important;
}

@media (max-width: 768px) {
  .product-banner {
    height: 320px;
  }
}
